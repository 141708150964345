import React, { useContext, useEffect } from "react";
import styles from "./Main.module.scss";

import { Link } from 'react-router-dom';
import Btn from '../../components/Btn';

import PathContext from "../../context/path";
const Main = () => {

  const path = useContext(PathContext);
  useEffect(() => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'ost.page',
      platform: 'etg-adventure',
      origin: 'etg-adventure',
      sitelang: 'en',
      pagetype: 'main'
    });
  }, []);
  return (
    <>
      <div className={styles.wrap} >
        <div className={styles.screen}>
          <div className={styles.img}>
            <picture>
              <source media="(max-width: 640px)" srcSet={`${path}/img/main-img-mobile.webp`} />
              <img src={`${path}/img/main-img.webp`} alt="" />
            </picture>
          </div>
          <div className={styles.info}>
            <h1 className={styles.title}>Adventure Beyond Frontiers</h1>
            <div className={styles.text}>Take an adventure with the Albatross to remember the values of ETG as he guides the way, and play two mini-games during his journey.</div>
            <Link to={'/london'} className={styles.btn}>
              <Btn text={`Let's go!`} />
            </Link>
            <div className={styles.symbol}>
              <img src={`${path}/img/main-symbol.webp`} alt="" />
            </div>
          </div>
        </div>
      </div>
    </>
  );

}

export default Main;
