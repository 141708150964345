import React, { useContext, useEffect, useRef, useState } from "react";
import styles from "./Denmark.module.scss";
import PathContext from "../../context/path";
import StateContext from "../../context/state";
import Message from '../../components/Message/Message';
import Test from '../../components/Test';
import { Link, Navigate } from 'react-router-dom';
import Btn from '../../components/Btn';
import Footer from '../../components/Footer';
const testData = [
  {
    'question': 'What did the Albatross say?',
    'answers': ['I have no idea, guys! It was already broken when I came. I suspect doves… ', ' I was riding your bike and I broke it. ', 'No need to search for the culprit! I’ll fix the bike, give me a stone! '],
    'failText': ['But this time considering the ETG value «Say It Like It Is». To us, it is important to always say what we think without sugarcoating things, while respecting the opinions and feelings of others, even doves.', 'By the way, in our company, it’s okay to make mistakes since we learn from them.'],
    'fail': 0,
    'success': 1,
    'successValue': 'Say It Like It Is'
  },
  {
    'question': 'What did the Albatross say?',
    'answers': ['Mmm, see you, geese!', 'I see you’re very upset about your «bike for geese» project. But to be honest, your prototype may not have been the best solution, especially when working against cold Scandic winters with difficult cycling conditions.', ' You should find out more information about cycling in winter, then maybe you’ll change your opinion.'],
    'failText': ['But this time consider the ETG value «Build Transparency». We build this company with transparency in mind in almost everything we do. So don’t ruin the trust. Your mates and colleagues deserve to know all the details!', 'By the way, in our company, it’s okay to make mistakes since we learn from them.'],
    'fail': 0,
    'success': 1,
    'successValue': 'Build Transparency'
  }
];
const Denmark = () => {
  const path = useContext(PathContext);
  const state = useContext(StateContext);
  const { isMobile, updateStoryStage, storyStage } = state;
  const refWalls = useRef([]);
  
  const refFooter = useRef(null);
  const [landingStage, setLandingStage] = useState(0);
  const updateLandingStage = () => setLandingStage(prev => prev + 1);
  useEffect(() => {
    window.scrollTo(0, 0);
    window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'ost.page',
    platform: 'etg-adventure',
    origin: 'etg-adventure',
    sitelang: 'en',
    pagetype: 'denmark'
  });
  }, []);
  useEffect(() => {
    updateStoryStage(prev => prev[3] === true);
  }, [updateStoryStage]);

  useEffect(() => {
    const walls = refWalls.current;
    if (landingStage > 0) {
      walls[0].classList.add(styles.shown);
    }
    if (landingStage > 1) {
      walls[1].classList.add(styles.shown);
      
      refFooter.current.classList.add(styles.shown);
    }
  }, [landingStage]);

  if (storyStage[2] === null) {
    return <Navigate to="/" />
  }
  return (
    <>
      <div className={styles.wrap}>
        <div className={`${styles.img} ${styles.img_1}`}>
          <picture>
            <source media="(max-width: 640px)" srcSet={`${path}/img/denmark-img-1-mobile.webp`} />
            <img src={`${path}/img/denmark-img-1.webp`} alt="" />
          </picture>
        </div>
        <div className={`${styles.chat} ${styles.chat_1}`}>
          <div className={styles.message} data-animate={'fade-in-up'}>
            <Message icon={'message-icon-1.webp'} text={'So you asked me what hygge means. For me, it means indulging yourself with shrimp all year long!'} theme={'viol'} width={'auto'} />
          </div>
        </div>
        <div className={styles.images}>
          <div className={`${styles.img} ${styles.img_2}`}>
            <picture>
              <source media="(max-width: 640px)" srcSet={`${path}/img/denmark-img-2-mobile.webp`} />
              <img src={`${path}/img/denmark-img-2.webp`} alt="" />
            </picture>
          </div>
          <div className={`${styles.img} ${styles.img_3}`}>
            <picture>
              <source media="(max-width: 640px)" srcSet={`${path}/img/denmark-img-3-mobile.webp`} />
              <img src={`${path}/img/denmark-img-3.webp`} alt="" />
            </picture>
          </div>
          <div className={`${styles.img} ${styles.img_4}`}>
            <picture>
              <source media="(max-width: 640px)" srcSet={`${path}/img/denmark-img-4-mobile.webp`} />
              <img src={`${path}/img/denmark-img-4.webp`} alt="" />
            </picture>
          </div>
        </div>

        <div className={`${styles.chat} ${styles.chat_2}`}>
          <div className={styles.message} data-animate={'fade-in-up'}>
            <Message icon={'message-icon-4.webp'} text={'Hi Albatross, did you see who broke our\nconcept-bike for migratory geese?'} theme={'viol'} width={'auto'} pos={'right'} />
          </div>
          <div className={styles.message} data-animate={'fade-in-up'} data-animate-delay="0.2">
            <Message icon={'message-icon-5.webp'} text={'We’ve been working on this project for 53 years. Today, we planned a presentation to introduce the idea, but...'} theme={'viol'} width={'auto'} pos={'right'} />
          </div>
        </div>

        <div className={styles.test}>
          <Test testCallback={updateLandingStage} data={testData[0]} use={4} />
        </div>

        <div className={styles.wall} ref={element => refWalls.current[0] = element}>
          <div className={`${styles.img} ${styles.img_5}`}>
            <picture>
              <source media="(max-width: 640px)" srcSet={`${path}/img/denmark-img-5-mobile.webp`} />
              <img src={`${path}/img/denmark-img-5.webp`} alt="" />
            </picture>
          </div>

          <div className={`${styles.chat} ${styles.chat_2}`}>
            <div className={styles.message} data-animate={'fade-in-up'}>
              <Message icon={'message-icon-4.webp'} text={'Ancestors entrusted us with bike construction for all geese tired of flying! But we failed! Sorry, father-goose!'} width={'auto'} theme={'viol'} pos={'right'} />
            </div>
            <div className={styles.message} data-animate={'fade-in-up'} data-animate-delay="0.2">
              <Message icon={'message-icon-6.webp'} text={'One more winter without traveling by bike!'} theme={'viol'} width={isMobile ? undefined : 'auto'} pos={'right'} />
            </div>
            <div className={styles.message} data-animate={'fade-in-up'} data-animate-delay="0.4">
              <Message icon={'message-icon-7.webp'} text={'Quack-quack-quack!'} theme={'viol'} width={'auto'} pos={'right'} />
            </div>
          </div>


          <div className={styles.test}>
            <Test testCallback={updateLandingStage} data={testData[1]} use={5} />
          </div>
        </div>

        <div className={styles.wall} ref={element => refWalls.current[1] = element}>
          <div className={`${styles.img} ${styles.img_6}`}>
            <picture>
              <source media="(max-width: 640px)" srcSet={`${path}/img/denmark-img-6-mobile.webp`} />
              <img src={`${path}/img/denmark-img-6.webp`} alt="" />
            </picture>
          </div>

          <div className={`${styles.chat} ${styles.chat_3}`}>
            <div className={styles.message} data-animate={'fade-in-up'}>
              <Message icon={'message-icon-4.webp'} text={'You’re a nice guy, Albatross! You told us the truth, however horrible it is! In Denmark, we highly appreciate honesty.'} theme={'viol'} width={'auto'} pos={'right'} />
            </div>
            <div className={styles.message} data-animate={'fade-in-up'} data-animate-delay="0.2">
              <Message icon={'message-icon-7.webp'} text={'Please stay with us till spring\nas a part of our pack!'} theme={'viol'} width={'auto'} pos={'right'} />
            </div>
            <div className={styles.message} data-animate={'fade-in-up'} data-animate-delay="0.4">
              <Message icon={'message-icon-6.webp'} text={' You might even meet a nice\nfemale goose and fall in love!  '} theme={'viol'} width={'auto'} pos={'right'} />
            </div>
          </div>

          <div className={`${styles.img} ${styles.img_7}`}>
            <picture>
              <source media="(max-width: 640px)" srcSet={`${path}/img/denmark-img-7-mobile.webp`} />
              <img src={`${path}/img/denmark-img-7.webp`} alt="" />
            </picture>
          </div>
          <div className={`${styles.chat} ${styles.chat_4}`}>
            <div className={styles.message} data-animate={'fade-in-up'}>
              <Message icon={'message-icon-1.webp'} text={'Sorry guys! I’m not ready for this! I’ve got lots of work far-far away from here.  '} theme={'viol'} width={'auto'} />
            </div>
          </div>
          <Link to={'/game-dinner'} className={styles.btn}>
            <Btn text={`New York, wait! We're on our way`} theme={'on_white'} />
          </Link>
        </div>
      </div>
      
      <div className={styles.footer} ref={refFooter}>
        <Footer />
      </div>
    </>
  );

}

export default Denmark;
