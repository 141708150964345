import React, { useContext, useEffect, useRef, useState } from "react";
import styles from "./Bali.module.scss";
import PathContext from "../../context/path";
import StateContext from "../../context/state";
import Message from '../../components/Message/Message';
import Test from '../../components/Test';
import { Link, Navigate } from 'react-router-dom';
import Btn from '../../components/Btn';
import Footer from '../../components/Footer';
const testData = [
  {
    'question': 'How do you think the Albatross responded?',
    'answers': [' Let’s try to figure it out! Show me exactly where the sound is coming from? ', 'Haha! Enough lemonade for you today! ', 'Maybe that’s just a flip-flop seller in the bush?'],
    'failText': ['But this time consider the ETG value «Appreciate Our People». We respect each other’s opinions and have no problem with lemonade. ', 'By the way, in our company, it’s okay to make mistakes since we learn from them.'],
    'fail': 1,
    'success': 0,
    'successValue': 'Appreciate Our People'
  },
  {
    'question': 'How do you think the Albatross responded?',
    'answers': ['It’s worth a shot, but don’t forget the Malevolent Supreme Spirit is still watching you!', 'Get back in the coconut! Less traveling — less responsibility — less worries.', 'Whoopee! Sounds like a real adventure! Let’s book a seat in a spirit business class on a direct flight to Alaska!'],
    'failText': ['But this time consider the ETG value «Embrace Freedom». We believe that every person deserves to be free and find their place, where they can do what they wish, and decide how to achieve their goals. Isn’t it better for the Spirit to take their warm hat and gloves and prepare for their big adventure?', 'By the way, in our company, it’s okay to make mistakes since we learn from them.'],
    'fail': 1,
    'success': 2,
    'successValue': 'Embrace Freedom'
  },
  {
    'question': 'What do you think the Albatross wants?',
    'answers': ['I want to be as rich as Elon Musk but never work a day! ', 'I don’t mind what I do, as long as I get paid enough.', 'No need for magic! I love my work. I’ve chosen it consciously, and now I’m passionate about having an opportunity to do what I want, improve, and develop my skills. '],
    'failText': ['But this time consider the ETG value «Love What We Do». We know that Mr Musk is passionate about his work and that’s why he produces top results. We believe that when we love what we do, we achieve the best results.', 'By the way, in our company, it’s okay to make mistakes since we learn from them.'],
    'fail': 0,
    'success': 2,
    'successValue': 'Love What We Do'
  }
];
const Bali = () => {
  const path = useContext(PathContext);
  const state = useContext(StateContext);
  const { isMobile, updateStoryStage, storyStage } = state;
  const refWalls = useRef([]);
  const refFooter = useRef(null);
  const [landingStage, setLandingStage] = useState(0);
  const updateLandingStage = () => setLandingStage(prev => prev + 1);
  useEffect(() => {
    window.scrollTo(0, 0);
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'ost.page',
      platform: 'etg-adventure',
      origin: 'etg-adventure',
      sitelang: 'en',
      pagetype: 'bali'
    });
  }, []);
  useEffect(() => {
    updateStoryStage(prev => prev[2] === true);
  }, [updateStoryStage]);

  useEffect(() => {
    const walls = refWalls.current;
    if (landingStage > 0) {
      walls[0].classList.add(styles.shown);
    }
    if (landingStage > 1) {
      walls[1].classList.add(styles.shown);
    }
    if (landingStage > 2) {
      walls[2].classList.add(styles.shown);
      refFooter.current.classList.add(styles.shown);
    }
  }, [landingStage]);

  if (storyStage[1] === null) {
    return <Navigate to="/" />
  }
  return (
    <>
      <div className={styles.wrap}>
        <div className={styles.images}>
          <div className={`${styles.img} ${styles.img_1}`}>
            <picture>
              <source media="(max-width: 640px)" srcSet={`${path}/img/bali-img-1-mobile.webp`} />
              <img src={`${path}/img/bali-img-1.webp`} alt="" />
            </picture>
          </div>
          <div className={`${styles.img} ${styles.img_2}`}>
            <picture>
              <source media="(max-width: 640px)" srcSet={`${path}/img/bali-img-2-mobile.webp`} />
              <img src={`${path}/img/bali-img-2.webp`} alt="" />
            </picture>
          </div>
          <div className={`${styles.img} ${styles.img_3}`}>
            <picture>
              <source media="(max-width: 640px)" srcSet={`${path}/img/bali-img-3-mobile.webp`} />
              <img src={`${path}/img/bali-img-3.webp`} alt="" />
            </picture>
          </div>
        </div>
        <div className={`${styles.chat} ${styles.chat_1}`}>
          <div className={styles.message} data-animate={'fade-in-up'}>
            <Message icon={'message-icon-1.webp'} text={'Holy shrimp! Did you hear that? That coconut just said something!'} theme={'viol'} width={'auto'} />
          </div>
        </div>
        <div className={styles.test}>
          <Test testCallback={updateLandingStage} data={testData[0]} use={1} />
        </div>
        <div className={styles.wall} ref={element => refWalls.current[0] = element}>
          <div className={`${styles.img} ${styles.img_4}`}>
            <picture>
              <source media="(max-width: 640px)" srcSet={`${path}/img/bali-img-4-mobile.webp`} />
              <img src={`${path}/img/bali-img-4.webp`} alt="" />
            </picture>
          </div>

          <div className={`${styles.chat} ${styles.chat_2}`}>
            <div className={styles.message} data-animate={'fade-in-up'}>
              <Message icon={'message-icon-3.webp'} text={'Thank you for getting me out of there, funny bird! 300 thousand years ago, I was imprisoned in the coconut by the Malevolent Supreme Spirit because I had such a passion for traveling! Now that I’m finally free again, I can travel! I can’t wait to go far, far away and see the snow!'} pos={'right'} theme={'viol'} width={'auto'} />
            </div>
          </div>
          <div className={styles.test}>
            <Test testCallback={updateLandingStage} data={testData[1]} use={2} />
          </div>
        </div>

        <div className={styles.wall} ref={element => refWalls.current[1] = element}>
          <div className={`${styles.img} ${styles.img_5}`}>
            <picture>
              <source media="(max-width: 640px)" srcSet={`${path}/img/bali-img-5-mobile.webp`} />
              <img src={`${path}/img/bali-img-5.webp`} alt="" />
            </picture>
          </div>

          <div className={`${styles.chat} ${styles.chat_3}`}>
            <div className={styles.message} data-animate={'fade-in-up'}>
              <Message icon={'message-icon-3.webp'} text={'Thank you again! I have always dreamt of becoming a traveler! Now you can choose what you want to be, and I’ll help make your dreams come true!'} pos={'right'} theme={'viol'} width={'auto'} />
            </div>
          </div>
          <div className={styles.test}>
            <Test testCallback={updateLandingStage} data={testData[2]} use={3} />
          </div>
        </div>

        <div className={styles.wall} ref={element => refWalls.current[2] = element}>
          <div className={`${styles.img} ${styles.img_6}`}>
            <picture>
              <source media="(max-width: 640px)" srcSet={`${path}/img/bali-img-6-mobile.webp`} />
              <img src={`${path}/img/bali-img-6.webp`} alt="" />
            </picture>
          </div>

          <div className={`${styles.chat} ${styles.chat_4}`}>
            <div className={styles.message} data-animate={'fade-in-up'}>
              <Message icon={'message-icon-3.webp'} text={'Okay, little funny bird! '} pos={'right'} theme={'viol'} width={'auto'} />
            </div>
            <div className={styles.message} data-animate={'fade-in-up'} data-animate-delay="0.2">
              <Message icon={'message-icon-1.webp'} text={'Let me go, it’s too hot here!'} theme={'viol'} width={'auto'} />
            </div>
          </div>
          <Link to={'/denmark'} className={styles.btn}>
            <Btn text={`Let's go!`} theme={'on_white'} />
          </Link>

        </div>
      </div>
      <div className={styles.footer} ref={refFooter}>
        <Footer />
      </div>
    </>
  );

}

export default Bali;
