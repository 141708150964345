import React, { useContext } from "react";
import styles from "./Footer.module.scss";
import PathContext from "../../context/path";
import StateContext from "../../context/state";
const Footer = (props) => {
  const path = useContext(PathContext);
  const state = useContext(StateContext);
  const { isMobile } = state;
  const {location, theme} = props;
  if (location === 'game-dinner' || location === 'game-bag'  || location === 'main' || isMobile) {
    return null;
  }
  let classWrap = styles.wrap;
  if (location === 'london' || location === 'new-york' || theme === 'invert') {
    classWrap = `${styles.wrap} ${styles.wrap_invert}`
  }

  return (
    <>
      <div className={classWrap}>

        <a href='https://www.emergingtravel.com/?utm_source=brand&utm_medium=promo-domain-landing&utm_campaign=adventure.emergingtravel.com&utm_term=header-etg-logo' target='_blank' rel='noreferrer' className={`${styles.logo} `}>
          <img src={`${path}/img/logo-emerging.svg`} alt="" />
        </a>
        <div className={styles.create}>
          <span>Created by agency</span>
          <a href='https://www.storyworks.digital/' target="_blank" rel='noreferrer'>
            <img src={`${path}/img/storyworks-logo.svg`} alt="" />
          </a>
        </div>
      </div>
    </>
  );

}

export default Footer;
