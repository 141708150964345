import React, { useContext, useEffect, useRef } from "react";
import styles from "./Message.module.scss";
import PathContext from "../../context/path";
import StateContext from "../../context/state";
const Message = (props) => {
  const path = useContext(PathContext);
  const state = useContext(StateContext);
  const { isMobile } = state;
  const { icon, text, theme, pos, width } = props;

  const refWrap = useRef(null);
  useEffect(() => {
    const wrap = refWrap.current;
    if (theme !== null && theme !== undefined && styles[`wrap_theme_${theme}`] !== undefined) {
      wrap.classList.add(styles[`wrap_theme_${theme}`]);
    }
    if (pos !== null && pos !== undefined && styles[`wrap_pos_${pos}`] !== undefined) {
      wrap.classList.add(styles[`wrap_pos_${pos}`]);
    }
    if (pos !== null && pos !== undefined && styles[`wrap_pos_${pos}`] !== undefined) {
      wrap.classList.add(styles[`wrap_pos_${pos}`]);
    }
    if (width !== null && width !== undefined && styles[`wrap_width_${width}`] !== undefined) {
      wrap.classList.add(styles[`wrap_width_${width}`]);
    }
  }, [pos, theme, width]);
  return (
    <>
      <div className={styles.wrap} ref={refWrap}>
        <div className={styles.icon}>
          <img src={`${path}/img/${icon}`} alt="" />
        </div>
        <div className={styles.text}>
          {text}
        </div>
      </div>
    </>
  );

}

export default Message;
