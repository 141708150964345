import React, { useContext } from "react";
import styles from "./Header.module.scss";
import PathContext from "../../context/path";
import StateContext from "../../context/state";
import { Link } from 'react-router-dom';
const Header = (props) => {
  const path = useContext(PathContext);
  const state = useContext(StateContext);
  const { isMobile } = state;
  const { location } = props;
  let locElement = null;
  if (location !== null && location !== undefined && location !== 'main' && location !== 'game-bag') {
    let text = '';
    switch (location) {
      case 'london':
        text = 'London, 17.30'
        break;
      case 'bali':
        text = 'Bali, 8.20'
        break;
      case 'denmark':
        text = 'Denmark, 12.15'
        break;
      case 'new-york':
        text = 'New York, 14:30'
        break;
      case 'game-dinner':
        text = 'somewhere over Paris'
        break;

      default:
        break;
    }
    locElement = <div className={styles.loc}>
      <img src={`${path}/img/loc-icon.svg`} alt="" />
      {text}
    </div>
  }
  if (location === 'game-dinner' || location === 'game-bag') {
    return null;
  }
  if (location === 'main') {
    locElement = <div className={styles.create}>
      <span>Created by agency</span>
      <a href='https://www.storyworks.digital/' target="_blank" rel='noreferrer'>
        <img src={`${path}/img/storyworks-logo.svg`} alt="" />
      </a>
    </div>
  }
  return (
    <>
      <div className={styles.wrap}>

        <a href='https://www.emergingtravel.com/?utm_source=brand&utm_medium=promo-domain-landing&utm_campaign=adventure.emergingtravel.com&utm_term=header-etg-logo' target='_blank' rel='noreferrer' className={`${styles.logo} `}>
          <img src={`${path}/img/logo-emerging.svg`} alt="" />
        </a>
        {locElement}
        
      </div>
    </>
  );

}

export default Header;
