import * as PIXI from 'pixi.js';
import gsap from 'gsap';
PIXI.utils.clearTextureCache();
PIXI.settings.RENDER_OPTIONS.hello = false;

export default class Bag {
    constructor(container, path, isMobile, handleProgress, handleEnd, handleWin, handleInit) {
        this.isInit = false;
        this.isStart = false;
        this.isFinish = false;
        this.container = container;
        this.handleProgress = handleProgress;
        this.handleEnd = handleEnd;
        this.handleWin = handleWin;
        this.handleInit = handleInit;
        this.isMobile = isMobile;
        this.path = path;
        
        this.speedObjects = 7;
        this.options = {
            width: 1440,
            height: 900,
            bagSize: [255, 434],
            bagPos: [285, 593, 901],
            objectPos: [416, 725, 1025],
        };
        this.sizeFactor = 1;
        if (this.isMobile) {
            this.sizeFactor = 2;
            
        this.speedObjects = 7;
            this.options = {
                width: 375,
                height: 680,

                bagSize: [137, 234],
                bagPos: [16, 119, 222],
                objectPos: [81, 185, 287],
            };
        }

        this.init();

    }

    init() {
        this.app = new PIXI.Application({
            width: this.options.width,
            height: this.options.height,
            backgroundAlpha: 0,
            antialias: true,
            utils: {
                clearTextureCache: true
            },
            resolution: 2
        });

        this.initStates();
        this.initLayers();
        this.initTextures();
        this.initTicker();
        this.container.appendChild(this.app.view);

    }
    initStates() {
        this.tl = gsap.timeline();
        this.bagPosIndex = 1;
        this.tickCounter = 0;
        this.tickCounterTarget = 40;
        this.objectCounter = 0;
        this.lastObjectPosIndex = -1;
        this.handleProgress(this.objectCounter);
    }
    initLayers() {

        this.layerBg = new PIXI.Container();
        this.layerBag = new PIXI.Container();
        this.layerObjects = new PIXI.Container();
        this.app.stage.addChild(this.layerBg);
        this.app.stage.addChild(this.layerObjects);
        this.app.stage.addChild(this.layerBag);

    }
    initTextures() {
        PIXI.Assets.reset()
        PIXI.Assets.add({ alias: 'bg', src: `${this.path}/img/bag/bg${this.isMobile? '-mobile' : ''}.webp` });
        PIXI.Assets.add({ alias: 'bag', src: `${this.path}/img/bag/bag.webp` });
        PIXI.Assets.add({ alias: 'glowGood', src: `${this.path}/img/bag/glow-good.webp` });
        PIXI.Assets.add({ alias: 'glowBad', src: `${this.path}/img/bag/glow-bad.webp` });
        PIXI.Assets.add({ alias: 'spritesheetGood', src: `${this.path}/img/bag/spritesheet-good.json` });
        PIXI.Assets.add({ alias: 'spritesheetBad', src: `${this.path}/img/bag/spritesheet-bad.json` });
        const texturePromise = PIXI.Assets.load(['spritesheetGood', 'spritesheetBad', 'bg', 'bag', 'glowGood', 'glowBad']);
        texturePromise.then((resources) => {
            if (this.app === null) return;

            const layerBg = new PIXI.Sprite(resources['bg']);
            layerBg.width = this.options.width;
            layerBg.height = this.options.height;
            layerBg.x = 0;
            layerBg.y = 0;
            this.layerBg.addChild(layerBg);

            const bagContainer = new PIXI.Container();
            bagContainer.width = this.options.bagSize[0];
            bagContainer.height = this.options.bagSize[1];
            bagContainer.x = this.options.bagPos[this.bagPosIndex];
            bagContainer.y = this.options.height;
            bagContainer.pivot.set(origin.x, this.options.height);
            const bag = new PIXI.Sprite(resources.bag);
            bag.width = this.options.bagSize[0];
            bag.height = this.options.bagSize[1];
            bag.x = 0;
            bag.y = this.options.height - this.options.bagSize[1] - 28;
            this.bag = bag;
            const glowGood = new PIXI.Sprite(resources.glowGood);
            glowGood.width = this.options.bagSize[0];
            glowGood.height = this.isMobile ? 122 : 226 ;
            glowGood.x = 0;
            glowGood.y = this.options.height - this.options.bagSize[1] - 27 - 28;
            glowGood.alpha = 0;
            this.bagGlowGood = glowGood;
            bagContainer.addChild(glowGood);
            const glowBad = new PIXI.Sprite(resources.glowBad);
            glowBad.width = this.options.bagSize[0];
            glowBad.height = this.isMobile ? 122 : 226 ;
            glowBad.x = 0;
            glowBad.y = this.options.height - this.options.bagSize[1] - 27 - 28;
            glowBad.alpha = 0;
            this.bagGlowBad = glowBad;
            bagContainer.addChild(glowBad);
            bagContainer.addChild(bag);
            this.bagContainer = bagContainer;
            this.layerBag.addChild(this.bagContainer);

            const spritesheetGood = resources.spritesheetGood;
            const spritesheetBad = resources.spritesheetBad;
            this.objectsTexures = [spritesheetGood.textures, spritesheetBad.textures];

            this.handleInit();
            this.isInit = true;
        });

    }
    drawBag(index) {
        this.bagPosIndex = index;
        // this.bagContainer.x = this.options.bagPos[this.bagPosIndex];
        // gsap.to(this.bagContainer, { x: this.options.bagPos[this.bagPosIndex], duration: 0.1 });
        let coef = this.bagContainer.x - this.options.bagPos[this.bagPosIndex] > 0 ? 1 : -1;
        // gsap.fromTo(this.bagContainer.skew , {x: 0}, {x: 0.02*coef, duration: 0.2, yoyo: true, repeat: 1});
    }
    updateBag(delta){
        const speed = 60*delta;
        // console.log('update');
        if(this.bagContainer.x === this.options.bagPos[this.bagPosIndex]) return;

        if(this.bagContainer.x < this.options.bagPos[this.bagPosIndex]){            
            if(this.bagContainer.x + speed > this.options.bagPos[this.bagPosIndex]){
                this.bagContainer.x = this.options.bagPos[this.bagPosIndex];
                return;
            }else{
                this.bagContainer.x += speed;
            }
            // console.log('+++');
        }

        if(this.bagContainer.x > this.options.bagPos[this.bagPosIndex]){            
            if(this.bagContainer.x - speed < this.options.bagPos[this.bagPosIndex]){
                this.bagContainer.x = this.options.bagPos[this.bagPosIndex]
                return;
            }else{
                this.bagContainer.x -= speed;
            }
            // console.log('---');
        }
    }
    drawObjects() {
        if (this.objectsTexures === undefined || this.objectsTexures.length === 0 || this.objectCounter >= 20 || !this.isStart) return;
        let index;
        const isGood = this.randomInteger(0, 1) === 0 ? true : false;
        const posIndex = this.randomInteger(0, 2);
        if (this.lastObjectPosIndex === posIndex) {
            this.drawObjects();
            return;
        }
        let texture;
        if (isGood) {
            index = this.randomInteger(1, 18);
            texture = this.objectsTexures[0][`good_${index}.png`];
        } else {
            index = this.randomInteger(1, 10);
            texture = this.objectsTexures[1][`bad_${index}.png`];
        }
        this.lastObjectPosIndex = posIndex;
        // console.log(`object-${index}.png`);
        // console.log( this.objectsTexures);
        // console.log(texture);
        const object = new PIXI.Sprite(texture);
        object.width = texture.orig.width / (this.isMobile ? 4 : 2);
        object.height = texture.orig.height / (this.isMobile ? 4 : 2);
        if(this.isMobile && !isGood && index ===1){
            object.width = texture.orig.width / 3;
        object.height = texture.orig.height / 3;
        }
        object.x = this.options.objectPos[posIndex];
        object.y = -object.height;
        object.rotation = (this.randomInteger(0, 180) * Math.PI) / 180;
        object.isGood = isGood;
        object.anchor.x = 0.5;
        object.anchor.y = 0.5;
        object.posIndex = posIndex;
        object.isActive = true;
        gsap.to(object, { rotation: `+=${(this.randomInteger(0, 180) * Math.PI) / 180}`, repeat: -1, duration: 10 });
        this.layerObjects.addChild(object);
    }

    updateObjects(delta) {
        if (this.layerObjects.children.length === 0 && !this.isStart) return;
        this.tickCounter += 0.5 * delta;
        if (this.tickCounter > 250 / this.speedObjects) {
            this.drawObjects();
            this.tickCounter = 0;
        }
        if (this.layerObjects.children.length === 0) return;
        this.layerObjects.children.forEach(element => {
            element.y += this.speedObjects * delta;

            if (!element.isActive) return;
            if (element.posIndex === this.bagPosIndex && element.y + element.height / 2 - 90 > this.bag.y && element.y + element.height / 2 - 90 < this.bag.y + this.bag.height / 3) {
                this.suctionObject(element, true)

            } else if (element.y > this.options.height - 28) {
                this.suctionObject(element)

                // this.endGame(false);
            }
        });
    }
    suctionObject(object, isBag = false) {
        object.isActive = false;
        if (isBag) {
            gsap.to(object, { alpha: 0.5, duration: 0.1 });
            gsap.to(object, { y: `+=50`, duration: 0.1 });
            gsap.to(object.scale, { x: this.isMobile? 0.1 : 0.3, y: this.isMobile? 0.1 : 0.3, duration: 0.1 });
            if (object.isGood) {

                gsap.fromTo(this.bagGlowGood, { alpha: 0 }, { alpha: 1, duration: 0.3, yoyo: true, repeat: 1 });
            } else {

                gsap.fromTo(this.bagGlowBad, { alpha: 0 }, { alpha: 1, duration: 0.3, yoyo: true, repeat: 1 });
            }
        } else {

            gsap.to(object, { alpha: 0, duration: 0.1 });
        }
        setTimeout(() => {
            this.layerObjects.removeChild(object);
            if (isBag && this.isStart) {
                if (object.isGood) {
                    this.setProgress();
                } else {
                    this.endGame(false);
                }
            }
        }, 100);
    }
    setProgress() {
        if (this.objectCounter <= 19) {
            this.objectCounter += 1;
            // this.speedObjects += this.isMobile ? 0.3 : 0.3;
            if (this.objectCounter >= 20) {
                this.endGame(true);
            }
            this.handleProgress(this.objectCounter);
        }
    }
    initTicker() {
        if (this.isTickerInit) {
            this.app.ticker.start();
            return;
        }
        this.isTickerInit = true;
        this.app.ticker.add((delta) => {
            if (this.app === null || this.app === undefined || !this.isInit) return;
            this.updateObjects(delta);
            this.updateBag(delta);
        })
    }
    handleKeyDown(e) {
        // ненадо зажимать
        if (e.repeat) return;
        // право 
        if (e.keyCode === 39 && this.bagPosIndex < 2) {
            this.bagPosIndex++;
        }
        // лево
        if (e.keyCode === 37 && this.bagPosIndex > 0) {
            this.bagPosIndex--;
        }
        this.drawBag(this.bagPosIndex);
    }
    handleTouchStart(e) {
        // ненадо зажимать
        const coef = this.container.offsetWidth/this.options.width;

        const carWidth = this.options.bagSize[0]*coef;
        const borderLeft = this.isMobile ? window.innerWidth / 3 : window.innerWidth / 2 - carWidth;
        const borderRight = this.isMobile ? window.innerWidth * 2/ 3 : window.innerWidth / 2 + carWidth ;
        if (e.touches[0].pageX < borderLeft) {
            this.bagPosIndex = 0;
        } else if (e.touches[0].pageX > borderRight) {
            this.bagPosIndex = 2;
        } else {
            this.bagPosIndex = 1;
        }
        this.drawBag(this.bagPosIndex);
    }
    addListener() {
        this.currentKeyHandler = this.handleKeyDown.bind(this);
        this.currentTouchHandler = this.handleTouchStart.bind(this);
        document.addEventListener('keydown', this.currentKeyHandler);
        document.addEventListener('touchstart', this.currentTouchHandler);

    }
    removeListener() {
        document.removeEventListener('keydown', this.currentKeyHandler);
        document.removeEventListener('touchstart', this.currentTouchHandler);

    }

    shuffler(array) {
        let counter = array.length,
            temp, index;
        while (counter > 0) {
            index = Math.floor(Math.random() * counter);
            counter--;
            temp = array[counter];
            array[counter] = array[index];
            array[index] = temp;
        }
        return array;
    }

    randomInteger(min, max) {
        let rand = min + Math.random() * (max + 1 - min);
        return Math.floor(rand);
    }
    endGame(bool) {
        this.isStart = false;
        this.removeListener();
        this.handleWin(bool);

        setTimeout(() => {
            this.handleEnd(true);

        }, 2000)

    }
    destroy(bool) {
        if (this.app === null) return;
        this.container.innerHTML = '';
        this.removeListener();
        this.app.ticker.remove();
        this.app.destroy();
        this.app = null;
        PIXI.utils.clearTextureCache();
        if (bool) {
            this.init();
        }
    }
    start() {
        this.isStart = true;
        this.addListener();

    }

    restart() {

        this.layerObjects.removeChildren();
        this.isStart = true;
        this.drawBag(1);
        this.addListener();
        this.initStates();
    }
}