import React, { useContext, useEffect, useRef, useState } from "react";
import styles from "./GameDinner.module.scss";
import PathContext from "../../context/path";
import StateContext from "../../context/state";
import Dinner from '../../games/dinner';
import PopupStart from '../../components/PopupStart/PopupStart';
import Btn from '../../components/Btn/Btn';
import { Link, Navigate } from 'react-router-dom';
const GameDinner = () => {
  const path = useContext(PathContext);
  const state = useContext(StateContext);
  const { isMobile, updateStoryStage, storyStage } = state;

  const refWrap = useRef(null);
  const refGame = useRef(null);
  const refApp = useRef(null);
  const refPopup = useRef(null);
  const refBtn = useRef(null);
  const [progress, setProgress] = useState(0);
  const [popupOpen, setPopupOpen] = useState(true);
  const [isEnd, setEnd] = useState(false);
  const [isWin, setWin] = useState(false);
  const [isInit, setInit] = useState(false);


  const updateWin = (bool) => {

    setEnd(true);
    setWin(bool);
    setTimeout(() => {

      setPopupOpen(true);
    }, 3000);
  };
  const updateInit = () => setInit(true);
  const updateRestart = (e) => {

    e.preventDefault();
    const btn = refBtn.current;
    btn.classList.remove(styles.push);
    refApp.current.restart();

    setPopupOpen(false);
    setTimeout(() => {
      setEnd(false)
    }, 600);
  };
  const updateStart = (e) => {
    e.preventDefault();
    setPopupOpen(false);
  };

  useEffect(() => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'ost.page',
      platform: 'etg-adventure',
      origin: 'etg-adventure',
      sitelang: 'en',
      pagetype: 'game-dinner'
    });
    const btn = refBtn.current;
    const pushHandle = () => {
      btn.classList.add(styles.push);
    };
    const upHandle = () => {
      btn.classList.remove(styles.push);
    };
    btn.addEventListener('click', pushHandle);
    // btn.addEventListener('mousedown', pushHandle);
    // btn.addEventListener('mouseup', upHandle);
    return () => {
      btn.removeEventListener('click', pushHandle);
      // btn.removeEventListener('mousedown', pushHandle);
      // btn.removeEventListener('mouseup', upHandle);
    }
  }, []);

  useEffect(() => {
    const wrap = refGame.current;
    const app = new Dinner(wrap, path, isMobile, updateWin, updateInit);
    refApp.current = app;
    refApp.current.start();
    return () => {
      if (refApp.current !== null) {
        refApp.current.destroy();
        refApp.current = null;
        setProgress(0);
        setPopupOpen(true);
      }
    };
  }, [path, isMobile]);
  useEffect(() => {
    if (isInit) {
      setTimeout(() => {
        refWrap.current.classList.add(styles.init);
        refGame.current.classList.add(styles.init);
        // refPopup.current.classList.add(styles.init);
      }, 100);
    }
  }, [isInit]);

  useEffect(() => {
    let timer;
    if (popupOpen) {
      timer = setTimeout(() => {
        refPopup.current.classList.add(styles.active);
      }, 50);
    } else {
      refPopup.current.classList.remove(styles.active);
    }
    return () => {
      clearTimeout(timer);
    }
  }, [popupOpen]);
  useEffect(() => {
    if (!isEnd) return;
    if (popupOpen) {
      window.dataLayer.push({
        'event': 'ost.popup.showed',
        'ost.event.target': 'game-result',
        'ost.event.game': 2,
        'ost.event.score': isWin ? 3 : 0
      });

    }
  }, [isEnd, isWin, popupOpen, progress]);

  useEffect(() => {
    updateStoryStage(prev => prev[4] === true);
  }, [updateStoryStage]);

  let popupBody = null;
  if (!isEnd) {
    popupBody = <div className={styles.popup__body} key={`popupBody-${isEnd}`}>
      <div className={styles.popup__title}>The Albatross likes to have a shrimp dinner on a plane.</div>
      <div className={styles.popup__text}>Click the <span>STOP</span> button when the lunch box with the shrimps appears in all windows. </div>
      <div className={styles.popup__btns}>
        <a href='#' className={styles.popup__btn} onClick={updateStart}>
          <Btn text={`Let's go`} theme={'on_white'} size={'small'} />
        </a>
      </div>
    </div>
  } else {
    popupBody = <div className={styles.popup__body}>
      <div className={styles.popup__title}>
        {isWin ?
          'Yay!'
          :
          'Sorry, game over…'
        }
      </div>
      <div className={styles.popup__text}>
        {isWin ?
          <p key={`text-${isWin}`}>
            Thank you so much! Let’s move on to the next adventure!
          </p>
          :
          <p key={`text-${isWin}`}>
            {/* One shrimp is good, but three is better!  */}
            Try again, but watch carefully.
            <span>3 screens = 3 shrimps</span>
          </p>
        }

      </div>
      <div className={styles.popup__btns}>
        <a href='#' className={styles.popup__btn} onClick={updateRestart}>
          <Btn text={`Replay`} theme={'on_white'} size={'small'} />
        </a>
        <Link to={'/new-york'} className={styles.popup__btn}>
          <Btn text={`Next`} theme={'border'} size={'small'} />
        </Link>
      </div>
    </div>
  }
  if (storyStage[3] === null) {
    return <Navigate to="/" />
  }
  return (
    <>
      <div className={styles.popup} ref={refPopup}>
        {popupBody}
      </div>
      <div className={`${styles.wrap}`} ref={refWrap}>
        <div className={styles.wrapper}>
          <div className={styles.game} ref={refGame}></div>
          <div className={styles.btn} ref={refBtn} onClick={() => refApp.current.stop()}><div className={styles.btn__inner}>STOP</div>
            <div className={styles.btn__wing}>
              <img src={`${path}/img/dinner-wing.svg`} alt="" />
            </div>
          </div>
        </div>
      </div>
    </>
  );

}

export default GameDinner;
