import React, { useContext, useEffect, useRef } from "react";
import styles from "./Btn.module.scss";
import PathContext from "../../context/path";
import StateContext from "../../context/state";
const Btn = (props) => {
  const path = useContext(PathContext);
  const state = useContext(StateContext);
  const { isMobile } = state;
  const { text, theme, size, again } = props;
  const refWrap = useRef(null);
  useEffect(() => {
    const wrap = refWrap.current;
    if (theme !== null && theme !== undefined && styles[`wrap_theme_${theme}`] !== undefined) {
      wrap.classList.add(styles[`wrap_theme_${theme}`]);
    }
    if (size !== null && size !== undefined && styles[`wrap_size_${size}`] !== undefined) {
      wrap.classList.add(styles[`wrap_size_${size}`]);
    }
  }, [size, theme]);
  return (
    <>
      <div className={styles.wrap} ref={refWrap}>
        {text}
        {again ?
          <svg width="37" height="41" viewBox="0 0 37 41" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M9.81923 0.723743C10.2336 0.0252007 11.1366 -0.208107 11.847 0.2028L19.247 4.51437C19.5874 4.71689 19.8389 5.05444 19.9277 5.44627C20.0313 5.8381 19.9575 6.249 19.7355 6.58507L15.2955 13.2799C14.8367 13.9579 13.9189 14.1458 13.2381 13.7011C12.5573 13.255 12.3649 12.3451 12.8089 11.6686L15.5618 7.51992C8.38382 8.87591 2.95219 15.1378 2.95219 22.6559C2.95219 31.1661 9.90819 38.0649 18.4922 38.0649C27.0762 38.0649 34.0322 31.1661 34.0322 22.6559C34.0322 18.1433 32.0787 14.0871 28.9559 11.2665C28.3491 10.7206 28.3194 9.79158 28.867 9.19283C29.4146 8.59408 30.3469 8.55152 30.9537 9.09744C34.6685 12.4507 36.9922 17.2848 36.9922 22.6559C36.9922 32.7863 28.7042 41 18.4922 41C8.28019 41 -0.0078125 32.7863 -0.0078125 22.6559C-0.0078125 14.0636 5.94173 6.85212 13.9929 4.85922L10.3374 2.73281C9.64177 2.32191 9.40483 1.42375 9.81923 0.723743Z" fill="white" />
          </svg>

          :
          null
        }
      </div>
    </>
  );

}

export default Btn;
