
import React, { useEffect, useRef, useState } from "react";

import PathContext from "./context/path";
import StateContext from "./context/state";
import Main from './pages/Main';
import { BrowserRouter as Router, Routes, Route,  } from "react-router-dom";
import Wrap from './pages/Wrap';
import GameBag from './pages/GameBag';
import London from './pages/London';
import Bali from './pages/Bali';
import Denmark from './pages/Denmark';
import GameDinner from './pages/GameDinner';
import NewYork from './pages/NewYork';

const App = () => {
  const [isMobile, setMobile] = useState(true);
  // const [storyStage, setStoryStage] = useState([null,null,null,null,null]);
  const [testCounterArray, setTestCounterArray] = useState([0,0,0,0,0,0,0,0]);
  const [testCounter, setTestCounter] = useState(0);
  const [storyStage, setStoryStage] = useState([true,true,true,true,true]);
  const updateStoryStage = (array)=>  setStoryStage(array);
  const updateTestCounterArray= (array)=>  setTestCounterArray(array);
  useEffect(() => {
    const handleMobile = () => {
      if (window.innerWidth < 641 && !isMobile) {
        setMobile(true);
      }
      if (window.innerWidth > 640 && isMobile) {
        setMobile(false);
      }
    }
    handleMobile();
    window.addEventListener('resize', handleMobile);
    return () => {
      window.removeEventListener('resize', handleMobile);
    }
  }, [isMobile]);

  useEffect(()=>{
    let result = 0;
    testCounterArray.forEach(element => {
      result+=element;
    });
    setTestCounter(result);
    // setTestCounter(0);
  },[testCounterArray]);
  useEffect(() => {
    function setHeight() {
      var vh = window.innerHeight * 0.01;
      document.querySelector('html').style.setProperty('--vh', `${vh}px`);
    }
    setHeight();
    function setHeightDynamic() {
      var vh = window.innerHeight * 0.01;
      document.querySelector('html').style.setProperty('--vhd', `${vh}px`);
    }
    setHeightDynamic();
    window.addEventListener('resize', setHeightDynamic);
    return () => {

      window.removeEventListener('resize', setHeightDynamic);
    }
  }, []);
  const basename = '';
  const basenameContext = basename;

  return (
    <StateContext.Provider value={{ isMobile: isMobile, storyStage: storyStage, updateStoryStage:updateStoryStage, updateTestCounterArray: updateTestCounterArray, testCounterArray: testCounterArray, testCounter:testCounter}}>
      <PathContext.Provider value={basenameContext}>
        <Router basename={basename}>
          {/* <div className="wrap" ref={refWrap}> */}
          
            <Routes>
            <Route path="/" element={<Wrap />}>
              <Route index element={<Main />} />
              <Route path="london" element={<London />} />
              <Route path="game-bag" element={<GameBag />} />
              <Route path="bali" element={<Bali />} />
              <Route path="denmark" element={<Denmark />} />
              <Route path="game-dinner" element={<GameDinner />} />
              <Route path="new-york" element={<NewYork />} />
              </Route>
            </Routes>
          {/* </div> */}
        </Router>
      </PathContext.Provider>
    </StateContext.Provider>
  );

}


export default App;
