import React, { useContext, useEffect, useRef, useState } from "react";
import styles from "./GameBag.module.scss";
import PathContext from "../../context/path";
import StateContext from "../../context/state";
import Bag from '../../games/bag';
import Btn from '../../components/Btn/Btn';
import { Link, Navigate } from 'react-router-dom';
const GameBag = () => {
  const path = useContext(PathContext);
  const state = useContext(StateContext);
  const { isMobile, updateStoryStage, storyStage } = state;

  const refWrap = useRef(null);
  const refGame = useRef(null);
  const refApp = useRef(null);
  const refPopup = useRef(null);
  const [progress, setProgress] = useState(0);
  const [popupOpen, setPopupOpen] = useState(true);
  const [isEnd, setEnd] = useState(false);
  const [isWin, setWin] = useState(false);
  const [isInit, setInit] = useState(false);
  const [tempMobile, setTempMobile] = useState(false);
  const updateProgress = (num) => setProgress(num);
  const updateEnd = (bool) => {
    setEnd(bool);
    setPopupOpen(true);
  };
  const updateWin = (bool) => setWin(bool);
  const updateInit = () => setInit(true);
  const updateRestart = (e) => {
    e.preventDefault();
    refApp.current.restart();
    setPopupOpen(false);
    setTimeout(() => {
      setEnd(false)
    }, 600);
  };
  const updateStart = (e) => {
    e.preventDefault();
    if (refApp.current !== null) {
      refApp.current.start();
      setPopupOpen(false);
    }
  };
  useEffect(() => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'ost.page',
      platform: 'etg-adventure',
      origin: 'etg-adventure',
      sitelang: 'en',
      pagetype: 'game-bag'
    });
    const isMobileDevice = () => {
      // Получаем информацию о пользовательском агенте браузера
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      // Проверяем, содержит ли пользовательский агент подстроки, характерные для мобильных устройств
      const result = /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(userAgent.toLowerCase());
      setTempMobile(result)
    }

    isMobileDevice();
    window.addEventListener('resize', isMobileDevice);
    return () => {
      document.documentElement.style.setProperty('--vh', ``);
      window.removeEventListener('resize', isMobileDevice);
    }
  }, []);
  useEffect(() => {
    updateStoryStage(prev => prev[1] === true);
  }, [updateStoryStage]);

  useEffect(() => {
    const wrap = refGame.current;
    const app = new Bag(wrap, path, isMobile, updateProgress, updateEnd, updateWin, updateInit);
    refApp.current = app;
    // app.start();
    return () => {
      if (refApp.current !== null) {
        refApp.current.destroy();
        refApp.current = null;
        setProgress(0);
        setPopupOpen(true);
      }
    };
  }, [path, isMobile]);
  useEffect(() => {
    if (isInit) {
      setTimeout(() => {
        refWrap.current.classList.add(styles.init);
        refGame.current.classList.add(styles.init);
        // refPopup.current.classList.add(styles.init);
      }, 100);
    }
  }, [isInit]);
  useEffect(() => {
    let timer;
    if (popupOpen) {
      timer = setTimeout(() => {
        refPopup.current.classList.add(styles.active);
      }, 50);
    } else {
      refPopup.current.classList.remove(styles.active);
    }
    return () => {
      clearTimeout(timer);
    }
  }, [popupOpen]);

  useEffect(()=>{
    if(!isEnd) return;
    if(popupOpen){
      window.dataLayer.push({
        'event':            'ost.popup.showed',
        'ost.event.target': 'game-result',
        'ost.event.game':   1,
        'ost.event.score':  progress
    });
    
    }
  },[isEnd, popupOpen, progress]);
  let popupBody = null;
  if (!isEnd) {
    popupBody = <div className={styles.popup__body}>
      <div className={styles.popup__title}>Help the Albatross pack his bag!</div>
      <div className={styles.popup__text}>
        {tempMobile ?
          <p>Tap on the suitcase to catch things.</p>
          :
          <p>Move the suitcase using the buttons on the keyboard to catch things.</p>
        }
       

          {/* <picture>
            <source media="(max-width: 640px)" srcSet={`${path}/img/popup-icon-mobile.svg`} />
            <img src={`${path}/img/popup-icon.svg`} alt="" />
          </picture> */}
          {tempMobile ?
           <div className={`${styles.popup__icon} ${styles.popup__icon_mobile}`}>
            <img src={`${path}/img/popup-icon-mobile.svg`} alt="" />
            </div>
            :
            
           <div className={styles.popup__icon}>
            <img src={`${path}/img/popup-icon.svg`} alt="" />
            
            </div>
          }
        
        <p>Catch only the things you need for the trip, or you’ll lose!</p>
      </div>
      <div className={styles.popup__btns}>
      <a href='#' className={styles.popup__btn} onClick={updateStart}>
          <Btn text={`Let's go`} theme={'on_white'} size={'small'} />
        </a>
      </div>
    </div>
  } else {
    popupBody = <div className={styles.popup__body}>
      <div className={styles.popup__title}>
        {isWin ?
          'Yay!'
          :
          'Sorry, game over…'
        }
      </div>
      <div className={styles.popup__text}>
        {isWin ?
          <>
            Thank you so much, my friend! <br />
            The adventure has just started, but you’re already a great help!
          </>
          :
          <>
            You don’t need this item. It's so big, and it’s taking up half the suitcase! <br /> Do you want to try again?
          </>
        }

      </div>
      <div className={styles.popup__btns}>
        <a href='#' className={styles.popup__btn} onClick={updateRestart}>
          <Btn text={`Replay`} theme={'on_white'} size={'small'} />
        </a>
        <Link to={'/bali'} className={styles.popup__btn}>
          <Btn text={`Next`} theme={'border'} size={'small'} />
        </Link>
      </div>
    </div>
  }
  if (storyStage[0] === null) {
    return <Navigate to="/" />
  }
  return (
    <>
      <div className={styles.popup} ref={refPopup}>
        {popupBody}
      </div>
      <div className={`${styles.wrap}`} ref={refWrap}>
        <div className={styles.wrapper}>
          <div className={styles.counter}>{progress}/20</div>
          <div className={styles.game} ref={refGame}></div>
        </div>
      </div>
    </>
  );

}

export default GameBag;
