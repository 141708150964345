import React, { useContext, useEffect, useRef, useState } from "react";
import styles from "./NewYork.module.scss";
import PathContext from "../../context/path";
import StateContext from "../../context/state";
import { Link, Navigate } from 'react-router-dom';
import Message from '../../components/Message/Message';
import Test from '../../components/Test';
import Btn from '../../components/Btn';
import Footer from '../../components/Footer';
const testData = [
  {
    'question': 'What did the Albatross say?',
    'answers': ['To find a Capybara water hotel, I should think as Capybaras do! I’ll try thinking outside the box to find the most suitable hotel!', 'Dear Capybaras, spend your vacation in your bath at home, please!', 'Hey T-Rex, let’s book a room designed for big rabbits, surely that will suit the Capybaras. Plus, it will be cheaper!'],
    'failText': ['But  this time consider the ETG value «Be Your Own CEO». Every team member should treat their work as not just a set of tasks, but as their own mini-business.', 'By the way, in our company, it’s okay to make mistakes since we learn from them.'],
    'fail': 1,
    'success': 0,
    'successValue': 'Be Your Own CEO'
  },
  {
    'question': 'What did the Albatross say?',
    'answers': ['What did you say about my granny Albatross? I don’t want to know about your language, but it sounds aggressive.', ' I’ll call my boss to hire an interpreter.', ' Friends, give me two minutes. I’ll find basic phrases in Capybarian language by using the online translator to start a discussion! '],
    'failText': ['But this time consider the ETG value «Grow Together». We care deeply about everyone’s individual growth. No matter what you learn: programming or a pair of Сapybarian words — you grow, and we grow together.', 'By the way, in our company, it’s okay to make mistakes since we learn from them.'],
    'fail': 0,
    'success': 2,
    'successValue': 'Grow Together'
  },
  {
    'question': 'What did the Albatross say?',
    'answers': [' I knew it, Man! When Capybaras start talking to me I should quit immediately. ', 'Duly noted: differing views\nwere expressed!', 'Go on! One more try, think carefully before answering, show me please where should I go?'],
    'failText': ['Let’s try again, but this time consider the ETG value «Persevere». In business, sometimes we experience stranger things than talking Capybaras, but these only make us stronger and more determined to succeed.', 'By the way, in our company, it’s okay to make mistakes since we learn from them.'],
    'fail': 0,
    'success': 2,
    'successValue': 'Persevere'
  }
];
const NewYork = () => {
  const path = useContext(PathContext);
  const state = useContext(StateContext);
  const { isMobile, storyStage, testCounter, updateTestCounterArray } = state;
  // const { isMobile, storyStage,  updateTestCounterArray } = state;
  // const testCounter = 8;
  const refBtn = useRef(null);
  const refImg = useRef(null);

  const refFooter = useRef(null);
  const refWalls = useRef([]);
  const refScreen = useRef(null);
  const refShripmsContainer = useRef(null);
  const refShripms = useRef([]);
  const [landingStage, setLandingStage] = useState(0);
  const [isShownEnd, setShownEnd] = useState(false);
  const updateLandingStage = () => setLandingStage(prev => prev + 1);
  useEffect(() => {
    window.scrollTo(0, 0);
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'ost.page',
      platform: 'etg-adventure',
      origin: 'etg-adventure',
      sitelang: 'en',
      pagetype: 'new-york'
    });
  }, []);
  useEffect(() => {
    const walls = refWalls.current;
    if (landingStage > 0) {
      walls[0].classList.add(styles.shown);
    }
    if (landingStage > 1) {
      walls[1].classList.add(styles.shown);
    }
    if (landingStage > 2) {
      walls[2].classList.add(styles.shown);
      
      refFooter.current.classList.add(styles.shown);
      if (!isShownEnd) {

        window.dataLayer = window.dataLayer || [];
        setShownEnd(true);
        
        if (testCounter >= 8) {
          window.dataLayer.push({
            'event':            'ost.click',
            'ost.click.target': 'final-result-high'
        });
        
        } else if (testCounter >= 5) {
          window.dataLayer.push({
            'event':            'ost.click',
            'ost.click.target': 'final-result-mid'
        });
        
        } else {
          window.dataLayer.push({
            'event':            'ost.click',
            'ost.click.target': 'final-result-low'
        });
        
        }
      }
    }
  }, [isShownEnd, landingStage, testCounter]);
  const sayGoodbuy = () => {
    window.scrollTo({
      top: window.scrollY + refImg.current.getBoundingClientRect().top - 20,
      behavior: "smooth",
    });
    setTimeout(() => {
      
    refImg.current.classList.add(styles.show);
    refBtn.current.classList.add(styles.disable);
    }, 500);
  }
  const hideHandler = (number) => {

    const shripms = refShripms.current;
    shripms[number].classList.add(styles.hide);
  }
  if (storyStage[4] === null) {
    return <Navigate to="/" />
  }
  let finalChat = null;
  let finalText = null;
  let finalShrimp = null;
  let finalBtn = <div className={styles.btn} ref={refBtn} onClick={sayGoodbuy}>
    <Btn text={'Say goodbye'} />
  </div>;
  if (testCounter >= 8) {
    finalChat = <div className={`${styles.chat} ${styles.chat_4}`}>
      <div className={styles.message}>
        <Message icon={'message-icon-1.webp'} text={'Wow! Thank you, ETG! I’ve learned so many interesting and useful things during the trip. Now I know how to love my life, trust my colleagues, and work as my own CEO.\n\nI’m ready to start my new trip! Say goodbye and push the button!'} theme={'viol'} width={'auto'} />
      </div>
    </div>;
  } else if (testCounter >= 5) {
    finalChat = <div className={`${styles.chat} ${styles.chat_4}`}>
      <div className={styles.message} >
        <Message icon={'message-icon-1.webp'} text={'I’m ready to start my new trip! Say goodbye and push the button!'} theme={'viol'} width={'auto'} />
      </div>
    </div>;
  } else {
    finalChat = <div className={styles.final__info} >

      <span>Oh! There are too many shrimps. Сlick to clear image</span>
      <picture>
        <source media="(max-width: 640px)" srcSet={`${path}/img/final-icon-mobile.webp`} />
        <img src={`${path}/img/final-icon.webp`} alt="" />
      </picture>
    </div>;
    finalText = <div className={styles.final__text}>
      If you follow the company values and apply them in your work, any route you choose will become easier. And then we can leave the shrimp for the paella!
    </div>
    finalBtn = <Link to="/bali" className={styles.btn} onClick={() => { updateTestCounterArray([0, 0, 0, 0, 0, 0, 0, 0]) }}>
      <Btn text={'Let’s try again!'} again={true} />
    </Link>;
    let shrimpArray = [];
    for (let index = 0; index < 9; index++) {
      const item = <div className={`${styles.shrimp} ${styles[`shrimp_${index + 1}`]}`} key={`shrimp-${index}`} ref={element => refShripms.current[index] = element} onClick={() => { hideHandler(index) }}>
        <picture>
          <source media="(max-width: 640px)" srcSet={`${path}/img/shrimp.webp`} />
          <img src={`${path}/img/shrimp.webp`} alt="" />
        </picture>
      </div>
      shrimpArray.push(item);
    }
    finalShrimp = <div className={styles.shrimps} ref={refShripmsContainer}>
      {shrimpArray}
    </div>
  }
  return (
    <>
      <div className={styles.wrap}>
        <div className={`${styles.img} ${styles.img_1}`}>
          <picture>
            <source media="(max-width: 640px)" srcSet={`${path}/img/new-york-img-1-mobile.webp`} />
            <img src={`${path}/img/new-york-img-1.webp`} alt="" />
          </picture>
        </div>
        <div className={`${styles.chat} ${styles.chat_1}`}>
          <div className={styles.message} data-animate={'fade-in-up'}>
            <Message icon={'message-icon-8.webp'} text={'Hi Albo! Join our team!'} theme={'viol'} pos={'right'} width={'auto'} />
          </div>
          <div className={styles.message} data-animate={'fade-in-up'} data-animate-delay="0.2">
            <Message icon={'message-icon-9.webp'} text={'Your task here is to find a special water hotel for a Capybara tourist group from South America!'} theme={'viol'} pos={'right'} width={'auto'} />
          </div>
        </div>
        <div className={styles.images}>
          <div className={`${styles.img} ${styles.img_2}`}>
            <picture>
              <source media="(max-width: 640px)" srcSet={`${path}/img/new-york-img-2-mobile.webp`} />
              <img src={`${path}/img/new-york-img-2.webp`} alt="" />
            </picture>
          </div>
          <div className={`${styles.img} ${styles.img_3}`}>
            <picture>
              <source media="(max-width: 640px)" srcSet={`${path}/img/new-york-img-3-mobile.webp`} />
              <img src={`${path}/img/new-york-img-3.webp`} alt="" />
            </picture>
          </div>
        </div>
        <div className={styles.test}>
          <Test testCallback={updateLandingStage} data={testData[0]} use={6} />
        </div>

        <div className={styles.wall} ref={element => refWalls.current[0] = element}>
          <div className={`${styles.img} ${styles.img_4}`}>
            <picture>
              <source media="(max-width: 640px)" srcSet={`${path}/img/new-york-img-4-mobile.webp`} />
              <img src={`${path}/img/new-york-img-4.webp`} alt="" />
            </picture>
          </div>

          <div className={styles.test}>
            <Test testCallback={updateLandingStage} data={testData[1]} use={7} />
          </div>
        </div>

        <div className={styles.wall} ref={element => refWalls.current[1] = element}>
          <div className={`${styles.img} ${styles.img_5}`}>
            <picture>
              <source media="(max-width: 640px)" srcSet={`${path}/img/new-york-img-5-mobile.webp`} />
              <img src={`${path}/img/new-york-img-5.webp`} alt="" />
            </picture>
          </div>
          <div className={`${styles.chat} ${styles.chat_2}`}>
            <div className={styles.message} data-animate={'fade-in-up'}>
              <Message icon={'message-icon-1.webp'} text={'(speaking in Сapybarian) That’s interesting! So, where did you say the Capybara water hotel is situated?'} theme={'viol'} width={'auto'} />
            </div>
          </div>
          <div className={styles.test}>
            <Test testCallback={updateLandingStage} data={testData[2]} use={8} />
          </div>
        </div>

        <div className={styles.wall} ref={element => refWalls.current[2] = element}>
          <div className={`${styles.images} ${styles.images_2}`}>
            <div className={`${styles.img} ${styles.img_6}`}>
              <picture>
                <source media="(max-width: 640px)" srcSet={`${path}/img/new-york-img-6-mobile.webp`} />
                <img src={`${path}/img/new-york-img-6.webp`} alt="" />
              </picture>
            </div>
            <div className={`${styles.img} ${styles.img_7}`}>
              <picture>
                <source media="(max-width: 640px)" srcSet={`${path}/img/new-york-img-7-mobile.webp`} />
                <img src={`${path}/img/new-york-img-7.webp`} alt="" />
              </picture>
            </div>
            <div className={`${styles.img} ${styles.img_8}`}>
              <picture>
                <source media="(max-width: 640px)" srcSet={`${path}/img/new-york-img-8-mobile.webp`} />
                <img src={`${path}/img/new-york-img-8.webp`} alt="" />
              </picture>
            </div>
          </div>
          <div className={`${styles.chat} ${styles.chat_3}`}>
            <div className={styles.message} data-animate={'fade-in-up'}>
              <Message icon={'message-icon-10.webp'} text={'Dear Albatross! You’re awarded for your contribution to the development of global Сapybara tourism! Hooray! '} theme={'viol'} pos={'right'} width={'auto'} />
            </div>
            <div className={styles.message} data-animate={'fade-in-up'} data-animate-delay="0.2">
              <Message icon={'message-icon-1.webp'} text={'$%^&& .. Is it okay that I’m speaking in Сapybarian?'} theme={'viol'} width={'auto'} />
            </div>
          </div>
          <div className={styles.final}>
            {finalChat}
            <div className={styles.final__screen}>
              {finalShrimp}
              <div className={styles.final__img} ref={refImg}>
                <picture>
                  <source media="(max-width: 640px)" srcSet={`${path}/img/new-york-img-9-mobile.webp`} />
                  <img src={`${path}/img/new-york-img-9.webp`} alt="" />
                </picture>
                <picture>
                  <source media="(max-width: 640px)" srcSet={`${path}/img/new-york-img-10-mobile.webp`} />
                  <img src={`${path}/img/new-york-img-10.webp`} alt="" />
                </picture>
              </div>
            </div>
            {finalText}
            {finalBtn}
            <div className={styles.footer}>
              <div className={styles.footer__text}>
                Learn more about Emerging Travel Group on our website
              </div>
              <div className={styles.footer__symbol}>
                <img src={`${path}/img/footer-symbol.svg`} alt="" />
              </div>

              <a href='https://www.emergingtravel.com/?utm_source=brand&utm_medium=promo-domain-landing&utm_campaign=adventure.emergingtravel.com&utm_term=learn-more-about-etg' target='_blank' rel='noreferrer' className={styles.footer__btn}><Btn text={'Let’s go!'} size={'medium'} theme={'border_white'} /></a>
            </div>
          </div>
        </div>
      </div>
      
      <div className={styles.footer2} ref={refFooter}>
        <Footer theme="invert" />
      </div>
    </>
  );

}

export default NewYork;
