import * as PIXI from 'pixi.js';
import gsap from 'gsap';
PIXI.utils.clearTextureCache();
PIXI.settings.RENDER_OPTIONS.hello = false;

export default class Dinner {
    constructor(container, path, isMobile,  handleWin, handleInit) {
        this.isInit = false;
        this.container = container;
        this.handleWin = handleWin;
        this.handleInit = handleInit;
        this.isMobile = isMobile;
        this.path = path;
        this.options = {
            width: 1440,
            height: 900,
        };
        this.sizeFactor = 1;
        if (this.isMobile) {
            this.sizeFactor = 2;
            this.options = {
                width: 375,
                height: 680

            };
        }

        this.init();

    }

    init() {
        this.app = new PIXI.Application({
            width: this.options.width,
            height: this.options.height,
            backgroundAlpha: 0,
            antialias: true,
            utils: {
                clearTextureCache: true
            },
            resolution: 2
        });

        this.initStates();
        this.initLayers();
        this.initTextures();

        this.indexGenerator();
        this.initTicker();
        this.container.appendChild(this.app.view);

    }
    initStates() {
        this.isStart = false;
        this.isFinish = false;
        this.speedFoods = 10;
        this.nextFoodIndex = [];
        this.nextFoodIndexTimer = null;
        this.resultFoodIndex = [0,0,0];
    }
    initLayers() {

        this.layerFoodBg1 = new PIXI.Container();
        this.layerFoodBg1.y = this.isMobile? 65 : 72;
        this.layerFoodBg1.x = this.isMobile? 16 : 20;
        this.layerFoodBg2 = new PIXI.Container();
        this.layerFoodBg2.y = this.isMobile? 228 : 72;
        this.layerFoodBg2.x = this.isMobile? 16 :  730;
        this.layerFoodBg3 = new PIXI.Container();
        this.layerFoodBg3.y =  this.isMobile? 391 : 486;
        this.layerFoodBg3.x = this.isMobile? 16 :  20;
        this.layerFood1 = new PIXI.Container();
        this.layerFood1.y = this.isMobile? 65 :  72;
        this.layerFood1.x = this.isMobile? 16 :  20;
        this.layerFood1.height = this.isMobile? 155 : 394;
        this.layerFood1.width = this.isMobile? 343 : 690;
        this.layerFood2 = new PIXI.Container();
        this.layerFood2.y = this.isMobile? 228 :  72;
        this.layerFood2.x = this.isMobile? 16 :  730;
        this.layerFood2.height = this.isMobile? 155 : 394;
        this.layerFood2.width = this.isMobile? 343 : 690;
        this.layerFood3 = new PIXI.Container();
        this.layerFood3.y = this.isMobile? 391 : 486;
        this.layerFood3.x = this.isMobile? 16 :  20;
        this.layerFood3.height = this.isMobile? 155 : 394;
        this.layerFood3.width = this.isMobile? 343 : 690;
        this.app.stage.addChild(this.layerFoodBg1);
        this.app.stage.addChild(this.layerFoodBg2);
        this.app.stage.addChild(this.layerFoodBg3);
        this.app.stage.addChild(this.layerFood1);
        this.app.stage.addChild(this.layerFood2);
        this.app.stage.addChild(this.layerFood3);

    }
    initTextures() {
        PIXI.Assets.reset();
        PIXI.Assets.add({ alias: 'bg', src: `${this.path}/img/dinner/bg${this.isMobile? '-mobile' : ''}.webp` });
        PIXI.Assets.add({ alias: 'spritesheet', src: `${this.path}/img/dinner/spritesheet.json` });
        const texturePromise = PIXI.Assets.load(['spritesheet', 'bg']);
        texturePromise.then((resources) => {
            if (this.app === null) return;
            this.bg = new PIXI.Sprite(resources['bg']);
            this.bg.width = this.isMobile? 343: 690;
            this.bg.height = this.isMobile? 155: 394;
            for (let index = 1; index < 4; index++) {
                const bg = new PIXI.Sprite(resources['bg']);
                bg.width = this.isMobile? 343: 690;
                bg.height = this.isMobile? 155: 394;
                bg.x = 0;
                bg.y = 0;
                this[`layerFoodBg${index}`].addChild(bg);

                const graphics = new PIXI.Graphics();
                graphics.beginFill(0xFF3300);
                graphics.drawRect(this[`layerFood${index}`].x, this[`layerFood${index}`].y, this.bg.width, this.bg.height);
                graphics.endFill();
                this[`layerFood${index}`].mask = graphics;
            }
            const spritesheet = resources.spritesheet;
            this.foodsTexures = spritesheet.textures;
            this.handleInit();
            this.isInit = true;
        });

    }

    drawFood(index, layer, offset = 0) {
        if (this.foodsTexures === undefined || this.foodsTexures.length === 0) return;


        const texture = this.foodsTexures[`food-${index}.png`];

        const food = new PIXI.Sprite(texture);
        food.width = texture.orig.width / (this.isMobile ? 4.5 : 2);
        food.height = texture.orig.height / (this.isMobile ? 4.5 : 2);
        food.x = this.bg.width + 46;
        food.y = this.bg.height / 2 - food.height / 2;
        food.index = index;
        food.isActive = true;
        layer.addChild(food);
        food.tween = gsap.fromTo(food, { x: food.x }, { x: food.x - this.bg.width - food.x, duration: 2, ease: "none" });
    }

    indexGenerator() {
        this.isTripple = !this.isTripple && this.randomInteger(0, 10) === 0 ? true : false;

        if (this.isTripple) {
            this.nextFoodIndex = [1, 1, 1];
        } else {
            this.nextFoodIndex = [this.randomInteger(1, 8), this.randomInteger(1, 8), this.randomInteger(1, 8)]
        }
        // console.log(this.nextFoodIndex);
        // this.nextFoodIndexTimer = setTimeout(() => {
        //     this.indexGenerator();
        // }, 300);
    }
    updateFoods(delta) {
        const layers = [this.layerFood1, this.layerFood2, this.layerFood3];

        if (this.isFinish) return;
        layers.forEach((layer, index) => {
            const children = layer.children;
            const childrenLength = layer.children.length;
            const offset = index * 20;
            if (childrenLength === 0 || Math.abs(children[childrenLength - 1].x) < this.bg.width - children[childrenLength - 1].width) {
                if (index === 0) {
                    this.indexGenerator();
                }
                this.drawFood(this.nextFoodIndex[index], layer, offset);
            } else if (layer.children[0].x < -layer.children[0].width) {
                layer.removeChild(layer.children[0]);
            }

        });
    }


    initTicker() {
        if (this.isTickerInit) {
            this.app.ticker.start();
            return;
        }
        this.isTickerInit = true;
        this.app.ticker.add((delta) => {
            if (this.app === null || this.app === undefined || !this.isInit) return;
            this.updateFoods(delta);
        })
    }


    shuffler(array) {
        let counter = array.length,
            temp, index;
        while (counter > 0) {
            index = Math.floor(Math.random() * counter);
            counter--;
            temp = array[counter];
            array[counter] = array[index];
            array[index] = temp;
        }
        return array;
    }

    randomInteger(min, max) {
        let rand = min + Math.random() * (max + 1 - min);
        return Math.floor(rand);
    }

    destroy(bool) {
        if (this.app === null) return;
        this.container.innerHTML = '';
        clearTimeout(this.nextFoodIndexTimer);
        this.app.ticker.remove();
        this.app.destroy();
        this.app = null;
        PIXI.utils.clearTextureCache();
        if (bool) {
            this.init();
        }
    }
    stop() {
        if(this.isFinish) return;
        this.isFinish = true;
        this.isStart = false;
        
        const layers = [this.layerFood1, this.layerFood2, this.layerFood3];
        layers.forEach((layer, index) => {
            const children = layer.children;
            
            let resultChildren;
            if(children.length < 2){
                resultChildren = children[0];
            }else{
                if(children[0].x + children[0].width > this.bg.width - children[1].x ){
                    resultChildren = children[0];
                }else{
                    resultChildren = children[1];
                }
            }
            this.resultFoodIndex[index] = resultChildren.index;
            const targetX = this.bg.width/2 - resultChildren.width/2;
            const offset = targetX - resultChildren.x ;
            children.forEach(element => {
                element.tween.pause();
                element.tween = gsap.to(element, { x: `-=100`, duration: 1, ease: "power3.out" })
                setTimeout(() => {
                    element.tween = gsap.to(element, { x: element.x + offset + 100, duration: 2, ease: "bounce.out" });
                }, 500);
            });
        });
        let result = this.resultFoodIndex.filter((element)=>element===1).length === 3;
    
        this.handleWin(result);
        
    }
    start() {
        this.isStart = true;
    }

    restart() {
        const layers = [this.layerFood1, this.layerFood2, this.layerFood3];
        layers.forEach((layer, index) => {
            const children = layer.children;
            layer.removeChildren();
            // console.log(children);
            // children.forEach(element => {
            //     layer.removeChild(element);
            // });
        });
        this.initStates();
        this.isFinish = false;
    }
}