import React, { useContext, useEffect, useRef, useState } from "react";
import styles from "./Test.module.scss";
import PathContext from "../../context/path";
import StateContext from "../../context/state";
const Test = (props) => {
  const path = useContext(PathContext);
  const state = useContext(StateContext);
  const { isMobile, updateTestCounterArray, testCounterArray } = state;
  const { data, use, testCallback } = props;
  const refPopup = useRef(null);
  const [result, setResult] = useState(0);
  const [isAnswered, setAnswered] = useState(false);
  const [answerIndex, setAnswerIndex] = useState(-1);
  const [callbackInit, setCallbackInit] = useState(false);
  useEffect(() => {
    const popup = refPopup.current;
    if (isAnswered) {
      popup.classList.add(styles.active);
    } else {
      popup.classList.remove(styles.active);
    }
    if (result > 0) {
      popup.classList.add(styles.fail);
    } else {

      popup.classList.remove(styles.fail);
    }
  }, [isAnswered, result]);
  useEffect(() => {
    const popup = refPopup.current;
    if (use !== null && use !== undefined && styles[`wrap_use_${use}`] !== undefined) {
      popup.classList.add(styles[`wrap_use_${use}`]);
    }
  }, [use]);
  const counterHandler = (number)=>{
    const array = [...testCounterArray];
    array[use-1] = number;
    updateTestCounterArray(array)
  }
  const selectAnswer = (index) => {
    if (isAnswered) return;
    
    window.dataLayer = window.dataLayer || [];
    if (data.success === index) {
      setResult(0);
      counterHandler(1);
      window.dataLayer.push({
        'event':            'ost.click',
        'ost.click.target': `test-${`${use}`.toLowerCase().replace(/ /g, '-')}-result-high`
    });
    } else if (data.fail === index) {
      setResult(1);
      counterHandler(0);
      window.dataLayer.push({
        'event':            'ost.click',
        'ost.click.target': `test-${`${use}`.toLowerCase().replace(/ /g, '-')}-result-low`
    });
    } else {
      setResult(2);
      counterHandler(0);
      window.dataLayer.push({
        'event':            'ost.click',
        'ost.click.target': `test-${`${use}`.toLowerCase().replace(/ /g, '-')}-result-mid`
    });
    }
    setAnswerIndex(index);
    setAnswered(true);
    if(!callbackInit && testCallback !== undefined){
      setCallbackInit(true);
      testCallback();
    }
  };
  const restart = () => {
    setAnswerIndex(-1);
    setAnswered(false);
  };

  let popupBody = null;
  switch (result) {
    case 0:
      popupBody = <div className={styles.popup__body} key={`answer-${result}`}>
        <div className={styles.popup__title}>
          Well done! Your answer reflects one of our values — «{data.successValue}».
        </div>
      </div>;
      break;
    case 1:
      popupBody = <div className={styles.popup__body}>
        <div className={styles.popup__title}>
          Let’s try again
        </div>
        <div className={styles.popup__text}>
          {data.failText.map((element, index) => <p key={`text-${index}`}>{element}</p>)}
        </div>
        <div className={styles.popup__restart} onClick={restart}>
          Take the test again
          <svg width="50" height="55" viewBox="0 0 50 55" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M13.0715 0.970875C13.6227 0.0338058 14.8238 -0.279168 15.7688 0.272049L25.6119 6.05587C26.0647 6.32754 26.3993 6.78034 26.5174 7.30597C26.6552 7.83159 26.557 8.38281 26.2617 8.83363L20.3558 17.8146C19.7456 18.7241 18.5247 18.976 17.6192 18.3795C16.7136 17.7811 16.4576 16.5605 17.0482 15.6529L20.71 10.0877C11.1622 11.9067 3.93726 20.3068 3.93726 30.3921C3.93726 41.8082 13.1898 51.0627 24.6079 51.0627C36.026 51.0627 45.2785 41.8082 45.2785 30.3921C45.2785 24.3386 42.6801 18.8973 38.5263 15.1136C37.7191 14.3812 37.6796 13.135 38.408 12.3318C39.1364 11.5286 40.3765 11.4715 41.1836 12.2039C46.1249 16.7022 49.2158 23.1869 49.2158 30.3921C49.2158 43.9816 38.1915 55 24.6079 55C11.0243 55 0 43.9816 0 30.3921C0 18.8658 7.91382 9.19187 18.6232 6.51847L13.7607 3.66597C12.8355 3.11475 12.5203 1.90991 13.0715 0.970875Z" fill="white" fillOpacity="1" />
          </svg>

        </div>
      </div>;
      break;
    case 2:
      popupBody = <div className={styles.popup__body}>
        <div className={styles.popup__title}>
          Nice try! <br />But that’s not quite right.
        </div>
        <div className={styles.popup__restart} onClick={restart}>
          Take the test again
          <svg width="50" height="55" viewBox="0 0 50 55" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M13.0715 0.970875C13.6227 0.0338058 14.8238 -0.279168 15.7688 0.272049L25.6119 6.05587C26.0647 6.32754 26.3993 6.78034 26.5174 7.30597C26.6552 7.83159 26.557 8.38281 26.2617 8.83363L20.3558 17.8146C19.7456 18.7241 18.5247 18.976 17.6192 18.3795C16.7136 17.7811 16.4576 16.5605 17.0482 15.6529L20.71 10.0877C11.1622 11.9067 3.93726 20.3068 3.93726 30.3921C3.93726 41.8082 13.1898 51.0627 24.6079 51.0627C36.026 51.0627 45.2785 41.8082 45.2785 30.3921C45.2785 24.3386 42.6801 18.8973 38.5263 15.1136C37.7191 14.3812 37.6796 13.135 38.408 12.3318C39.1364 11.5286 40.3765 11.4715 41.1836 12.2039C46.1249 16.7022 49.2158 23.1869 49.2158 30.3921C49.2158 43.9816 38.1915 55 24.6079 55C11.0243 55 0 43.9816 0 30.3921C0 18.8658 7.91382 9.19187 18.6232 6.51847L13.7607 3.66597C12.8355 3.11475 12.5203 1.90991 13.0715 0.970875Z" fill="white" fillOpacity="1" />
          </svg>

        </div>
      </div>;
      break;

    default:
      break;
  }
  return (
    <div>
      <div className={styles.wrap} data-animate={'fade-in-up'}>
        <div className={styles.popup} ref={refPopup}>
          {popupBody}
          <div className={`${styles.popup__img} ${styles[`popup__img_${result}`]}`} key={`img-${result}`}>
            <picture>
              <source media="(max-width: 640px)" srcSet={`${path}/img/test-img_${result}-mobile.webp`} />
              <img src={`${path}/img/test-img_${result}.webp`} alt="" />
            </picture>
          </div>
        </div>
        <div className={styles.body}>
          <div className={styles.question}>{data.question}</div>
          <div className={styles.answers}>
            {data !== undefined ?
              data.answers.map((element, index) =>
                <div className={styles.answer} key={`answer-${index}`} onClick={() => selectAnswer(index)}>
                  {element}
                </div>
              )
              :
              null}
          </div>
        </div>
      </div>
    </div>
  );

}

export default Test;
