import React, { useContext, useEffect, useRef, useState } from "react";
import styles from "./Wrap.module.scss";
import StateContext from "../../context/state";
import { Outlet, useMatch, useNavigate } from 'react-router-dom';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
const Wrap = () => {

  const navigate = useNavigate();
  const state = useContext(StateContext);
  const {storyStage} = state;
  const useCurrentPath = () => {
    const id = useMatch('/:id');
    if (id !== null && id.params.id !== undefined) {
      return id.params.id;
    }
    return 'main';
  }
  const tempPath = useCurrentPath().toLowerCase();

  useEffect(() => {
    const animElements = document.querySelectorAll('[data-animate]');
    let observerArray = [];
    animElements.forEach((element, index) => {
      let threshold = element.dataset.animateThreshould === undefined ? 0 : element.dataset.animateThreshould;
      let rootMargin = element.dataset.animateOffset === undefined ? '-10%' : element.dataset.animateOffset;
      let duration = element.dataset.animateDuration === undefined ? '0.7' : element.dataset.animateDuration;
      let delay = element.dataset.animateDelay === undefined ? '0' : element.dataset.animateDelay;
      let ease = element.dataset.animateEase === undefined ? null : element.dataset.animateEase;
      let length = element.dataset.animateLength === undefined ? null : element.dataset.animateLength;
      let trigger = element.dataset.animateTrigger === undefined ? null : element.dataset.animateTrigger;
      let triggerDelay = element.dataset.animateTriggerDelay === undefined ? 0.3 : element.dataset.animateTriggerDelay;
      let target = element.dataset.animateTarget === undefined ? null : element.dataset.animateTarget;
      
      if(delay !== '0'){
        element.style.transitionDelay = `${delay}s`;
      }
      if(duration !== '0.7'){
        element.style.transitionDuration = `${duration}s`;
      }
      if(ease !== null){
        element.style.transitionTimingFunction = ease;
      }
      if(length !== null){
        element.style.setProperty("--length", length);
      }
      if(target !== null){
        element.classList.add('trigger-disable');
      }
      
      let options = {
        threshold: threshold,
        rootMargin: `0% 100% ${rootMargin} 100%`
      }
      const observerCallback = (entry) =>{
        if (entry.isIntersecting && !entry.target.classList.contains(`animate`)) {
          entry.target.classList.add(`animate`);
          if(trigger !== null){
            let targetElement = document.querySelectorAll(`[data-animate-target="${trigger}"]`);
            setTimeout(()=>{
              targetElement.forEach(element => {
                
                element.classList.remove('trigger-disable');
              });
            }, triggerDelay*1000);
          }
        }
      }
      const observer = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
          observerCallback(entry)
        })
      }, options);

      observer.observe(element);
      observerArray.push(observer);
    });
    // const scrollHandler = ()=>{
    //   const animElements = document.querySelectorAll('[data-animate]:not(.animate)');
    //   if(animElements.length === 0) return;
      
    //   animElements.forEach(element => {
    //     console.log(element.getBoundingClientRect().bottom );
    //     if(element.getBoundingClientRect().top < window.innerHeight*0.9){
    //       element.classList.add(`animate`);
    //     }
    //   });
    // }
    // scrollHandler();
    // document.addEventListener('scroll', scrollHandler);
    return () => {
      animElements.forEach((element, index) => {
        observerArray[index].unobserve(element);
      });
      // document.removeEventListener('scroll', scrollHandler);
    }
  }, [tempPath]);
  
  return (
    <>
        <Header location={tempPath}/>
        <Outlet />
    </>
  );

}

export default Wrap;
