import React, { useContext, useEffect } from "react";
import styles from "./London.module.scss";
import PathContext from "../../context/path";
import StateContext from "../../context/state";
import Message from '../../components/Message/Message';
import { Link, Navigate } from 'react-router-dom';
import Btn from '../../components/Btn';
import Footer from '../../components/Footer';
const London = () => {
  const path = useContext(PathContext);
  const state = useContext(StateContext);
  const { isMobile, updateStoryStage, storyStage } = state;
  useEffect(() => {
    window.scrollTo(0, 0);
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'ost.page',
      platform: 'etg-adventure',
      origin: 'etg-adventure',
      sitelang: 'en',
      pagetype: 'london'
    });
  }, []);
  useEffect(() => {
    updateStoryStage(prev => prev[0] === true);
  }, [updateStoryStage]);

  return (
    <>
      <div className={styles.wrap}>
        <div className={styles.text}>
          <p>Let’s start from London. The weather is rainy today, but here in the ETG office, it is always warm and comfortable.</p>
          <p>What’s that? Someone is ringing the bell...</p>
        </div>
        <div className={`${styles.img} ${styles.img_1}`} data-animate={'fade-in-up'} >
          <picture>
            <source media="(max-width: 640px)" srcSet={`${path}/img/london-img-mobile.webp`} />
            <img src={`${path}/img/london-img.webp`} alt="" />
          </picture>
        </div>
        <div className={styles.chat} data-animate={'fade-chat'} data-animate-trigger={'chat'} data-animate-trigger-delay={1.6}>
          <div className={styles.message}>
            <Message icon={'message-icon-1.webp'} text={'Hi, ETG!'} />
          </div>
          <div className={styles.message}>
            <Message icon={'message-icon-2.webp'} text={'Hi, Albatross! What can I do for you?'} theme={'viol'} pos={'right'} />
          </div>
          <div className={styles.message}>
            <Message icon={'message-icon-1.webp'} text={'I heard that you can simplify travel to help anyone achieve the freedom to be where they desire, and to do what they love?'} />
          </div>
          <div className={styles.message}>
            <Message icon={'message-icon-2.webp'} text={'Yes, I can!'} theme={'viol'} pos={'right'} />
          </div>
          <div className={styles.message}>
            <Message icon={'message-icon-1.webp'} text={'And you create, distribute and operate the most convenient travel products, is that right?'} />
          </div>
          <div className={styles.message}>
            <Message icon={'message-icon-2.webp'} text={'Absolutely! '} theme={'viol'} pos={'right'} />
          </div>
          <div className={styles.message}>
            <Message icon={'message-icon-1.webp'} text={'Can you help me with my crazy travel idea?'} />
          </div>
          <div className={styles.message}>
            <Message icon={'message-icon-2.webp'} text={'With all my pleasure!\nLet’s get started!'} theme={'viol'} pos={'right'} />
          </div>
        </div>
        <div className={`${styles.img} ${styles.img_2}`} data-animate={'fade-in-up'} data-animate-target={'chat'}  >
          <picture>
            <source media="(max-width: 640px)" srcSet={`${path}/img/london-img-2.webp`} />
            <img src={`${path}/img/london-img-2.webp`} alt="" />
          </picture>
        </div>
        <Link to={'/game-bag'} className={styles.btn} data-animate={'fade-in-up'} data-animate-target={'chat'} data-animate-offset={isMobile ? '10%' : '0%'}>
          <Btn text={`Let's go!`} />
        </Link>
      </div>
      
      <Footer  theme={'invert'}/>
    </>
  );

}

export default London;
